@charset "utf-8";

// Our variables
$base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$code-font-family: Inconsolata, Monaco, Consolas, monospace;
$base-font-size:   16px;
$base-font-weight: 300;
$small-font-size:  0.875rem;
$big-font-size:    1.3rem;
$base-line-height: 1.5;

$spacing-unit:     2rem;
$max-width:        48rem;

$title-color:      #111;
$text-color:       #333;
$background-color: #fff;
$link-color:       #0645ad;

$grey-color:       #aaa;
$grey-color-light: lighten($grey-color, 25%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$on-palm:          600px;
$on-:              800px;

// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting"
;