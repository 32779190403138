@use "sass:math";

.underlined {
    flex: 1;
    text-decoration: none;
    background-image: linear-gradient(to right, yellow 0, yellow 100%);
    background-position: 0 1.2em;
    background-size: 0 100%;
    background-repeat: no-repeat;
    transition: background .5s;
    &:hover {
        background-size: 100% 100%;
    }
    &--thin {
        background-image: linear-gradient(to right, black 0, black 100%);
    }
    &--thick {
        background-position: 0 -0.1em;
    }
    &--offset {
        background-position: 0 0.2em;
        //didn't find another solution than mask the underline shape by a box shadow with the same color than the bg
        box-shadow: inset 0 -.5em 0 0 white;
    }
    &--gradient {
        background-position: 0 -0.1em;
        background-image: linear-gradient(to right, yellow 0, lightgreen 100%);
    }
    &--reverse {
        background-position: 100% -0.1em;
        transition: background 1s; //yep, that's a long link
        background-image: linear-gradient(to right, yellow 0, yellow 100%);
    }
}

/**
 * Site header
 */
.site-header {
    // border-bottom: 1px solid $grey-color-light;
    max-width: $max-width;

    p {
      font-size: $small-font-size;
    }
    .site-intro {
        font-size: 1.1rem;
    }

    h1 {
        a:hover {
            text-decoration: none;
        }
    }

    .header-image {
        height: auto; // to maintain aspect ratio
        float: right;
        margin-right: 1rem;
        margin-top: 2rem;
        margin-left: 2rem;
        margin-bottom: 2rem;
    }

    @include media-query($on-palm) {
        .header-image {
            max-width: 100px;
        }
    }
}

.smallcap {
    //text-transform: lowercase;
    //font-variant: small-caps;
    font-size: 1.5rem;
    font-weight: bold;

    a, a:hover {
        text-decoration: none;
        letter-spacing: 2px;
        background-color: #333;
        color: #eee;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
    }
}

/**
 * Page content
 */
.page-content {
    position: relative;
    padding: $spacing-unit ($spacing-unit * 0.75);
    box-sizing: border-box;
}
/**
 * Pages
 */
.home {
    section {
        margin: $spacing-unit 0 $spacing-unit 0;
        max-width: $max-width;
    }
}

.post-list > li {
    margin-bottom: calc($spacing-unit / 4);
    list-style-type: none;
    margin-left: -2rem;

    a {
        color: $title-color;
        text-decoration: none;
        font-weight: normal;
        margin-right: 0.25rem;

        &:hover {
            text-decoration: underline;
        }
    }

    .meta {
        font-size: $small-font-size;
        color: $grey-color;
        display: inline-block;
    }
    @include media-query($on-palm) {
        .meta {
            display: block;
        }
    }
}

.tag-title {
    color: $link-color;
}
/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    color: $title-color;
    font-size: 2rem;
    letter-spacing: -0.5px;
    line-height: 1.2;
    font-weight: bolder;
}

.post-meta {
    font-size: $base-font-size;
    font-family: $base-font-family;
    color: $grey-color;
    a, a:visited {
      color: $grey-color-dark;
    }
    .tags {
        a, a:visited {
            background: $grey-color-light;
            padding: 0.1rem 0.5rem;
        }
    }
}

.post-content {
    margin-bottom: $spacing-unit;
    font-weight: normal;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: $spacing-unit;
        font-weight: normal;
    }

    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.5rem;
    }

    h3 {
        font-size: 1.25rem;
    }

    h4,
    h5,
    h6 {
        font-size: 1rem;
    }


}

.copyright {
  margin-top: $spacing-unit;
  font-size: $small-font-size;
  font-family: $code-font-family;
  p {
    color: $grey-color;
    a, a:visited {
      color: $grey-color-dark;
    }
  }
}

.navigation {
    display: block;
    font-size: .875rem;
    font-weight: bolder;
}

.top-navigation {
    margin-bottom: $spacing-unit*1.5;
}

.bottom-navigation {
    margin-top: $spacing-unit*1.5;
    margin-bottom: $spacing-unit*0.75;
}

img.centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.post-content-image {
    max-width:100%
}

.embedded-email-form {
    margin: $spacing-unit 0 $spacing-unit 0;
}

.home .embedded-email-form {
    margin-top: -$spacing-unit;
    margin-bottom: math.div(-$spacing-unit, 2);
    margin-left: -20px;
    margin-right: -20px;
}

hr {
    border-top: 1px solid lighten($grey-color, 28%);
}